/* global customElements, window */
import WarpElement from '@warp-ds/elements-core';
import { css, html } from 'lit';

// To reduce bundle size, import only the components and icons you need
// https://github.com/warp-ds/elements?tab=readme-ov-file#import-components
// https://warp-ds.github.io/tech-docs/components/icons/
import '@warp-ds/elements/components/button';
import { trackBannerClick, trackViewEvent } from './utils.js';

export class BrazeContentCard extends WarpElement {
	static styles = [
		...WarpElement.styles, // Adds the global styles
		css`
			/** The line below is where the injected CSS goes, removing it means you get no CSS from the design system **/
			*,:before,:after{--w-rotate:0;--w-rotate-x:0;--w-rotate-y:0;--w-rotate-z:0;--w-scale-x:1;--w-scale-y:1;--w-scale-z:1;--w-skew-x:0;--w-skew-y:0;--w-translate-x:0;--w-translate-y:0;--w-translate-z:0}@media (min-width:768px){.md\\:h3{font-weight:700;font-size:var(--w-font-size-l);line-height:var(--w-line-height-l)}}.aspect-ratio{aspect-ratio:auto}.static{position:static}.max-h-full{max-height:100%}.max-w-4\\/10{max-width:40%}.my-24{margin-top:2.4rem;margin-bottom:2.4rem}.object-cover{object-fit:cover}.text-14{font-size:var(--w-font-size-s);line-height:var(--w-line-height-s)}@media (min-width:480px){.sm\\:p-24{padding:2.4rem}}@media (min-width:768px){.md\\:my-16{margin-top:1.6rem;margin-bottom:1.6rem}};
		`,
	];

	static properties = {
		card: { attribute: false },
		braze: { attribute: false },
	};

	constructor() {
		super();

		// Subscribe to braze content card updates, and set the card content on new changes.
		if (window.braze) {
			this.braze = window.braze;

			this.braze.subscribeToContentCardsUpdates((updates) => {
				// Filter cards to ensure only frontpage cards are shown
				const frontpageCards = updates.cards.filter((card) => card.extras?.cardLocation === 'frontpage');
				this.card = frontpageCards[0]; // Set content.
			});
			this.braze.requestContentCardsRefresh();
		}
	}

	render() {
		if (this.card && (this.card.title || this.card.description || this.card.imageUrl)) {
			trackViewEvent(this.card, this.braze);
			return html`
				<section
					id="frontpage-banner"
					card-id="${this.card.extras?.cardId}"
					class="flex justify-between grow relative overflow-hidden rounded-8 shadow-m my-24"
					aria-labelledby="fp-banner-header"
				>
					<div class="p-16 sm:p-24">
						<h2 class="h4 md:h3" id="fp-banner-header">${this.card.title}</h2>
						<p class="inline text-14 md:my-16">${this.card.description}</p>
						<div class="mr-8 mt-16">
							<w-button
								id="frontpage-banner-link"
								variant="primary"
								small
								href="${this.card.extras?.primaryButtonLink}"
								@click="${() => trackBannerClick(this.card, this.braze)}"
							>
								${this.card.extras?.primaryButtonText}
							</w-button>
						</div>
					</div>
					<img
						src="${this.card.imageUrl}"
						alt="${this.card.extras?.altText}"
						class="flex flex-1 items-center max-w-4/10 object-cover max-h-full"
						style="aspect-ratio: 10/3;"
					/>
				</section>
			`;
		}
	}
}

if (!customElements.get('braze-content-card')) {
	customElements.define('braze-content-card', BrazeContentCard);
}
