/* global customElements, window */
import WarpElement from '@warp-ds/elements-core';
import { css, html } from 'lit';
import '@warp-ds/elements/components/button';
import { trackBannerClick, trackViewEvent } from './utils.js';

export class FinnStaticBanner extends WarpElement {
	static styles = [
		...WarpElement.styles, // Adds the global styles
		css`
			/** The line below is where the injected CSS goes, removing it means you get no CSS from the design system **/
			*,:before,:after{--w-rotate:0;--w-rotate-x:0;--w-rotate-y:0;--w-rotate-z:0;--w-scale-x:1;--w-scale-y:1;--w-scale-z:1;--w-skew-x:0;--w-skew-y:0;--w-translate-x:0;--w-translate-y:0;--w-translate-z:0}@media (min-width:768px){.md\\:h3{font-weight:700;font-size:var(--w-font-size-l);line-height:var(--w-line-height-l)}}.aspect-ratio{aspect-ratio:auto}.static{position:static}.max-h-full{max-height:100%}.max-w-4\\/10{max-width:40%}.my-24{margin-top:2.4rem;margin-bottom:2.4rem}.object-cover{object-fit:cover}.text-14{font-size:var(--w-font-size-s);line-height:var(--w-line-height-s)}@media (min-width:480px){.sm\\:p-24{padding:2.4rem}}@media (min-width:768px){.md\\:my-16{margin-top:1.6rem;margin-bottom:1.6rem}};
		`,
	];

	static properties = {
		bannerImage: { type: String },
	};

	render() {
		const cardId = 'jobb24w34_frontpage_web';
		const buttonText = 'Sjekk ut mulighetene';

		const header = 'Du trodde du bare "trengte litt ferie", ja?';

		const subHeader = 'FINN har flest jobbmuligheter - sjekk dine nå!';

		if (typeof window !== 'undefined') {
			trackViewEvent(
				{
					extras: {
						cardId,
						primaryButtonText: buttonText,
					},
				},
				null,
			);
		}

		return html` <section
			id="frontpage-banner"
			card-id="${cardId}"
			class="flex justify-between grow relative overflow-hidden rounded-8 shadow-m my-24"
			aria-labelledby="fp-banner-header"
		>
			<div class="p-16 sm:p-24">
				<h2 class="h4 md:h3" id="fp-banner-header">${header}</h2>
				<p class="inline text-14 md:my-16">${subHeader}</p>
				<div class="mr-8 mt-16">
					<w-button
						id="frontpage-banner-link"
						variant="primary"
						small
						href="/job/fulltime/search.html?utm_campaign=jobb24w34&utm_content=flest_muligheter_ferie&utm_medium=frontpage&utm_source=egenreklame"
						@click="${() =>
							trackBannerClick(
								{
									extras: {
										cardId,
										primaryButtonText: buttonText,
									},
								},
								null,
							)}"
					>
						${buttonText}
					</w-button>
				</div>
			</div>
			<img
				src="${this.bannerImage}"
				alt="Pakke på bord"
				class="flex flex-1 items-center max-w-4/10 object-cover max-h-full"
				style="aspect-ratio: 10/3;"
			/>
		</section>`;
	}
}

if (!customElements.get('finn-static-banner')) {
	customElements.define('finn-static-banner', FinnStaticBanner);
}
