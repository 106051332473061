import pulse from '@finn-no/pulse-sdk';

export function trackBannerClick(card, braze) {
	braze?.logContentCardClick(card);
	pulse.trackEvent({
		type: 'Click',
		intent: 'Open',
		name: 'Braze content card',
		object: {
			type: 'UIElement',
			elementType: 'Button',
			position: `Frontpage:${card.extras?.primaryButtonText}`,
			name: card.extras?.cardId,
		},
		vertical: { name: 'common_pages' },
	});
}

export function trackViewEvent(card, braze) {
	braze?.logContentCardImpressions([card]);
	pulse.trackPageView({
		name: 'Braze content card',
		intent: 'Show',
		object: {
			type: 'UIElement',
			elementType: 'Card',
			position: `Frontpage:${card.extras?.primaryButtonText}`,
			name: card.extras?.cardId,
		},
		vertical: { name: 'common_pages' },
	});
}
